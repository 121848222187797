import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps, navigate } from '@reach/router';
import Layout from '../components/Layout/Layout';
import { CircularProgress, List, ListSubheader, Fab, Icon, Input, Typography } from '@mui/material';
import ListItem from '../components/ListItem/ListItem';
import dayjs from '../libs/dayjs';
import { MakeupData, MakeupType } from '../redux/modules/makeups/makeups.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getMakeups, setLocationKey } from '../redux/modules/makeups';
import {findAllMakeups, findAllRecurring} from '../redux/modules/makeups/makeups.thunks';
import { alpha, styled } from '@mui/material/styles';
import { getBase } from '../redux/modules/base';

const PREFIX = 'ListPage';

const classes = {
  root: `${PREFIX}-root`,
  ul: `${PREFIX}-ul`,
  subheader: `${PREFIX}-subheader`,
  subheaderMonth: `${PREFIX}-subheaderMonth`,
  fab: `${PREFIX}-fab`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.ul}`]: {
    padding: 0,
  },

  [`& .${classes.subheader}`]: {
    color: '#000',
    fontWeight: 'bold',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    top: '64px',
  },

  [`& .${classes.subheaderMonth}`]: {
    color: 'rgba(0, 0, 0, 0.9)',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    top: '64px',
    position: 'static',
    // margin: '0 -2rem 1rem',
    margin: '0 0 1rem',
    textAlign: 'center',
    fontSize: '1.5rem',
  },

  [`& .${classes.fab}`]: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: 1000,
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  }
}));

export interface MakeupGroups {
  date: string,
  month: number,
  makeups: MakeupData[],
}

const ListPage: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { isLoading, makeups, recurring, locationKey } = useSelector(getMakeups);
  const { rewindId } = useSelector(getBase);
  const [query, setQuery] = React.useState('');

  const filteredMakeups = React.useMemo<MakeupData[]>(() => {
    if (!query) {
      return makeups;
    }

    return makeups.filter(client => 
      `${client.firstName.toLowerCase()} ${client.lastName.toLowerCase()}`.includes(query.toLowerCase())
      || client.phone.replace(/ /g, '').includes(query)
    );
  }, [query, makeups]);

  const makeupGroups = useMemo(() => {
    const groups = new Set<string>();
    const allObjects = [...filteredMakeups, ...recurring];

    allObjects.sort((item1, item2) => {
      const item1Date = dayjs(item1.date);
      const item2Date = dayjs(item2.date);

      if (item1Date.isBefore(item2Date)) {
        return -1;
      }

      if (item1Date.isAfter(item2Date)) {
        return 1;
      }

      return 0;
    })

    allObjects.forEach(makeup => {
      const day = dayjs(makeup.date).format('YYYY-MM-DD');
      groups.add(day);
    });

    const newMakeUpGroups: MakeupGroups[] = Array.from(groups).map((day) => ({
      date: dayjs(day, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY'),
      month: dayjs(day, 'YYYY-MM-DD').get('month'),
      makeups: allObjects.filter(makeupItem => dayjs(makeupItem.date).format('YYYY-MM-DD') === day)
        .sort((a, b) => {
          const time1 = parseInt(a.time.split(':')[0]);
          const time2 = parseInt(b.time.split(':')[0]);
          if (a.isRecurring) {
            return -1;
          }
          if (time1 > time2) {
            return 1
          } else if (time1 < time2) {
            return -1;
          }
          return 0;
        }),
    }));

    return newMakeUpGroups;
  }, [filteredMakeups]);

  useEffect(() => {
    if (location && location.state && (location.state as any).makeup) {
      return;
    }
    dispatch(findAllMakeups())
    dispatch(findAllRecurring())
  }, [dispatch, location]);

  useEffect(() => {
    const shouldRewind = () => {
      setTimeout(() => {
        if (rewindId) {
          const element = document.getElementById(`${rewindId}-anchor`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 100);
    };

    if (!location || !location.state) {
      shouldRewind();
      return;
    }

    if (locationKey === location.key) {
      shouldRewind();
      return;
    }

    if (location.key) {
      dispatch(setLocationKey(location.key));
    }

    const { makeup } = (location.state as any);

    if (!makeup) {
      shouldRewind();
      return;
    }

    const element = document.getElementById(`${makeup._id}-anchor`);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      shouldRewind();
    }
  }, [dispatch, location, locationKey, rewindId]);

  const count = {
    makeups: filteredMakeups.filter(makeup => ![MakeupType.LESSON, MakeupType.RESERVATION, MakeupType.MODEL, MakeupType.BREAK].includes(makeup.type))
      .reduce((sum, makeup) => sum += makeup.peopleCount, 0),
    lessons: filteredMakeups.filter(makeup => makeup.type === MakeupType.LESSON).length,
  }

  const renderMakeupGroups = () => {
    let month: number;

    return makeupGroups.map(group => {
      const items = (
          <li key={`section-${group.date}`}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.subheader}>{group.date}</ListSubheader>
              {group.makeups.map(makeupEl => (
                  <ListItem
                      key={makeupEl._id}
                      {...makeupEl}
                  />
              ))}
            </ul>
          </li>
      );

      if (month === undefined) {
        month = group.month;
        return items;
      }

      if (month !== group.month) {
          month = group.month;
          const formatted = dayjs().set('month', month).format('MMMM');
          return (
              <>
                <ListSubheader key={`section-${formatted}-${group.date}`} className={classes.subheaderMonth}>{formatted}</ListSubheader>
                {items}
              </>
          )
      }

      return items;
    })
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <StyledLayout>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Icon>search</Icon>
        </div>
        <Input
          value={query}
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <List subheader={<li />} className={classes.root}>
        { renderMakeupGroups() }
      </List>
      <div>
        <Typography variant="subtitle2">
          Ilość makijaży: {count.makeups}
          <br />
          Ilość lekcji makijażu: {count.lessons}
        </Typography>
      </div>
      <Fab className={classes.fab} onClick={() => navigate('/create')}>
        <Icon>add</Icon>
      </Fab>
    </StyledLayout>
  );
}

export default ListPage
